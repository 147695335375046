
<div class="d-flex logo-container align-items-center justify-content-center">
    <img class="d-block mx-auto" id="logo" src="assets/logo-mayuma.svg" />
</div>

<nav>
    <p class="slogan">the app lab.</p>
    <ul>
        <li><a href="/imprint">Impressum</a></li>
        <li><a href="mailto:hello@mayuma.io">Contact us</a></li>
    </ul>
</nav>