import { Component, OnInit } from '@angular/core';
import axios from 'axios';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {


  constructor() { }

  async ngOnInit() {
  }

}


