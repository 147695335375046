<div class="d-flex align-items-center py-5" style="min-height: 100vh;">
    <div class="container text-white text-center">

        <h1>Impressum</h1>
        <p>
            mayuma GmbH<br>
            Ring 289<br>
            8230 Hartberg<br>
        </p>

        <p>
            Geschäftsführer: Jörg Bullmann<br>
            UID: ATU57901658<br>
            Firmenbuchnummer: FN247606b<br>
            Firmenbuchgericht: Landesgericht für ZRS Graz<br>
            Behörde gem. ECG (E-Commerce Gesetz): Bezirkshauptmannschaft Hartberg-Fürstenfeld<br>
            WKO-Eintrag Lt. G5 Abs. 1ECG <br>
        </p>

        <p>
            Haftungshinweis:<br>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. <br>
        </p>

        <a class="d-block" id="close-btn" href="/"><img src="assets/x-icon.svg" /></a>
    </div>
</div>


